<div class="modal-content">
    <div class="modal-header">
        <button type="button" class="btn-close" aria-label="Close" (click)="onDismiss()"></button>
    </div>
    <div class="modal-body">
        <h4 class="text-center">Continúe con el pago a través de VentiPay</h4>
        <div class="w-100 text-center d-flex flex-column gap-2 justify-content-center align-items-center">
            <img class="my-4" style="max-width: 150px; max-height: 150px; cursor: pointer;"
                src="/assets/images/logo-venti.svg" alt="Payment in process">
        </div>
        <div class="d-flex justify-content-center">
            <div class="mb-4 btn btn-primary btn-lg px-4" (click)="abrirEnlacePago()">
                Ir al pago
            </div>
        </div>
        <div class="text-center">El pago podría tardar unos minutos en ser procesado.</div>
        <div class="text-center">Una vez finalizado el proceso de pago, si la información de su cuenta no se ha actualizado, puede cerrar esta ventana y recargar nuevamente.</div>
    </div>
    <div class="modal-footer">
    </div>
</div>
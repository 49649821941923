import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MarkdownService } from 'ngx-markdown';
import { SuscripcionesPagoEnProcesoComponent } from '../suscripciones-pago-en-proceso/suscripciones-pago-en-proceso.component';
import { SentryService } from 'src/app/utils/sentry/sentry.service';
import { LoadingService } from 'ngx-kuv-tools';

@Component({
  selector: 'app-suscripciones-terminos',
  templateUrl: './suscripciones-terminos.component.html',
  styleUrls: ['./suscripciones-terminos.component.scss']
})
export class SuscripcionesTerminosComponent implements OnInit {

  @Input() tipo: string = '';
  @Input() plan: string = '';
  @Input() montoParcial: number = 0;

  md: string = '';
  constructor(
    private http: HttpClient,
    private loading: LoadingService,
    private mdService: MarkdownService,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private sentryService: SentryService,
  ) { }

  ngOnInit(): void {
    this.loadToS();
  }

  async loadToS() {
    this.loading.show();
    this.http.get(`/assets/tos/tos_web.md`, { responseType: 'text' }).subscribe({
      next: (res: any) => {
        this.loading.hide();
        this.md = this.mdService.compile(res);
      },
      error: (err: any) => {
        console.error(err);
        this.loading.hide();
        this.sentryService.captureException(err.error);
      }
    });
  }

  aceptar(): void {
    const modalRef = this.modalService.open(SuscripcionesPagoEnProcesoComponent, { size: 'lg', scrollable: true, centered: true, backdrop: 'static' });
    modalRef.componentInstance.tipo = this.tipo;
    modalRef.componentInstance.plan = this.plan;
    modalRef.componentInstance.montoParcial = this.montoParcial;
    modalRef.result.then((result: any) => {
      this.activeModal.close(true);
    }, (reason: any) => {
      this.activeModal.close(true);
    });
  }
}

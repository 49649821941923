import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from "@angular/core";
import * as Sentry from "@sentry/angular-ivy";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbDropdownModule, NgbModalModule, NgbModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxKuvToolsModule } from 'ngx-kuv-tools';
import { NgxKuvUtilsModule } from 'ngx-kuv-utils';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { UsuarioPassChangeComponent } from './components/usuario/usuario-pass-change/usuario-pass-change.component';
import { UsuarioProfileComponent } from './components/usuario/usuario-profile/usuario-profile.component';
import { LoginComponent } from './utils/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ModalDeleteModule } from './utils/modal-delete/modal-delete.module';
import { MarkdownModule } from 'ngx-markdown';
import { TosComponent } from './utils/tos/tos/tos.component';
import { ModalComponent } from './utils/modal/modal.component';
import { RecuperarContrasenaComponent } from './utils/recuperar-contrasena/recuperar-contrasena.component';
import { NuevaContrasenaComponent } from './utils/nueva-contrasena/nueva-contrasena.component';
import { SoporteComponent } from './utils/soporte/soporte/soporte.component';
import { VersionUpdateComponent } from './utils/version-update/version-update.component';
import { InfoAlertSiiModule } from './utils/info-alert-sii/info-alert-sii.module';
import { SharedModule } from './shared/shared.module';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { FullCalendarModule } from '@fullcalendar/angular';
import { ErrorUiModule } from './utils/error-ui/error-ui.module';

const maskConfig: Partial<IConfig> = {};

@NgModule({
  declarations: [
    AppComponent,
    UsuarioPassChangeComponent,
    UsuarioProfileComponent,
    RecuperarContrasenaComponent,
    NuevaContrasenaComponent,
    LoginComponent,
    TosComponent,
    HomeComponent,
    ModalComponent,
    SoporteComponent,
    VersionUpdateComponent,
  ],
  imports: [
    PickerModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MarkdownModule.forRoot(),
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    NgxKuvUtilsModule,
    NgbPopoverModule,
    NgbDropdownModule,
    HttpClientModule,
    NgbModalModule,
    NgxKuvToolsModule,
    NgSelectModule,
    NgxMaskModule.forRoot(maskConfig),
    NgSelectModule,
    ModalDeleteModule,
    InfoAlertSiiModule,
    SharedModule,
    FullCalendarModule,
    ErrorUiModule,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

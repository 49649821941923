<div class="modal-content">
    <div class="modal-header">
      
    </div>
    <div class="modal-body">
        <h5 class="mb-3 text-center">Usted no posee una suscripción activa.</h5>
        <h6 class="mb-5 text-center">Comuníquese con su agente de ventas para regularizar su situación.</h6>

        <h6 class="mb-3 text-center" *ngIf="esCuentaPrueba"><div
                class="d-flex gap-2 align-items-center justify-content-center"><a class="btn btn-success"
                    target="_blank" href="https://api.whatsapp.com/send?phone=56944758039"><i
                        class="fa-brands fa-whatsapp"></i></a> <a href="tel:56944758039">+56 9 4475 8039</a></div>
        </h6>
        <h6 class="mb-5 text-center" *ngIf="esCuentaPrueba"><a
                href="mailto:natalia@kuvemar.com">natalia@kuvemar.com</a></h6>

        <h6 class="mb-3 text-center" *ngIf="!esCuentaPrueba"><div
                class="d-flex gap-2 align-items-center justify-content-center"><a class="btn btn-success"
                    target="_blank" href="https://api.whatsapp.com/send?phone=56975155189"><i
                        class="fa-brands fa-whatsapp"></i></a> <a href="tel:56975155189">+56 9 4475 8039</a></div>
        </h6>
        <h6 class="mb-5 text-center" *ngIf="!esCuentaPrueba"><a
                href="mailto:ventas@kuvemar.com">ventas@kuvemar.com</a></h6>

        <!-- <h5 class="mb-5 text-center">O renueve su suscripción manualmente.</h5>
        <button class="btn btn-primary btn-lg w-100 text-center" (click)="renovarPlan()"><i class="fas fa-rocket"></i> Renovar Plan</button> -->
        <!-- <div (click)="openLink()" style="cursor: pointer;" class="d-flex justify-content-center align-items-center w-100 flex-column">
            <img style="max-width: 300px;" src="assets/system/K-PAGOS_NEGRO.png" alt="Logo KPagos">
            <h5 class="btn-link text-primary">Visitar KPagos</h5>
        </div> -->

        <div class="w-100 d-flex justify-content-center my-2">
            <div class="w-100 btn btn-primary text-center" (click)="pagarPlan()">Pagar Plan</div>
        </div>
        <div class="w-100 d-flex justify-content-center my-2">
            <div class="w-100 btn btn-outline-secondary text-center" (click)="cerrarSesion()">Cerrar Sesión</div>
        </div>
    </div>
    <div class="modal-footer">
    </div>
</div>
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DriveStep, Driver } from 'driver.js';

export const crearOTSteps = (driver: Driver): DriveStep[] => {
  return [
    {
      element: '.seccion-detalles-tutorial',
      popover: {
        title: 'Detalles',
        description: `
          ● Debe rellenar los campos no opcionales.
        `,
        side: "right",
        align: 'start',
      },
    },
    {
      element: '.vehiculo-tutorial',
      popover: {
        title: 'Vehículo',
        description: `
          ● Puede seleccionar su vehículo. <br>
          ● Puede editar un vehículo seleccionado. <br>
          ● Puede crear un nuevo vehículo. <br>`,
        side: "right",
        align: 'start',
      },
    },
    {
      element: '.cliente-tutorial',
      popover: {
        title: 'Cliente',
        description: `
          ● Puede seleccionar su cliente. <br>
          ● Puede editar un cliente seleccionado. <br>
          ● Puede crear un nuevo cliente.
        `,
        side: "right",
        align: 'start',
      },
    },
    {
      element: '.seccion-venta-tutorial',
      popover: {
        title: 'Ventas',
        description: `
          ● Sección para agregar servicios/productos.
        `,
        side: "right",
        align: 'start',
      },
    },
    {
      element: '.crear-productos-tutorial',
      popover: {
        title: 'Crear',
        description: `
          ● Puede crear productos y/o servicios.
        `,
        side: "right",
        align: 'start',
      },
    },
    {
      element: '.cargar-fotos-vehiculo-tutorial',
      popover: {
        title: 'Cargar fotos',
        description: `
          ● Puede cargar fotos de su vehículo.
        `,
        side: 'left',
        align: 'start',
      }
    },
    {
      element: '.agregar-productos-tutorial',
      popover: {
        title: 'Agregar Productos',
        description: `
          ● Para agregar productos a su venta.
        `,
        side: "right",
        align: 'start',
      },
    },
    {
      element: '.descuento-tutorial',
      popover: {
        title: 'Descuento',
        description: `
          ● Puede agregar un descuento al bruto de la venta.
        `,
        side: "right",
        align: 'start',
      },
    },
    {
      element: '.cambiar-descuento-tutorial',
      popover: {
        title: 'Tipo de Descuento',
        description: `
          ● El descuento puede ser por pesos o por % al hacer click en este botón.
        `,
        side: "right",
        align: 'start',
      },
    },
    {
      element: '.tipo-documento-tutorial',
      popover: {
        title: 'Tipo de Documento',
        description: `
          ● Las cotizaciones no descuentan stock de inventario.
        `,
      },
    },
    {
      element: '.metodo-pago-tutorial',
      popover: {
        title: 'Método de Pago',
        description: `
          ● Deshabilitado en las cotizaciones.
        `,
      },
    },
    {
      element: '.registrar-venta-tutorial',
      popover: {
        title: 'Registrar Venta',
        description: `
          ● Para guardar su venta.
        `,
      },
    },
  ];
};

export const crearVentaSteps = (driver: Driver): DriveStep[] => {
  return [
    {
      element: '.seccion-detalles-tutorial',
      popover: {
        title: 'Detalles',
        description: `
          ● Debe rellenar los campos no opcionales.
        `,
        side: "right",
        align: 'start',
      },
    },
    {
      element: '.cliente-tutorial',
      popover: {
        title: 'Cliente',
        description: `
          ● Puede seleccionar su cliente. <br>
          ● Puede editar un cliente seleccionado. <br>
          ● Puede crear un nuevo cliente.
        `,
        side: "right",
        align: 'start',
      },
    },
    {
      element: '.seccion-venta-tutorial',
      popover: {
        title: 'Ventas',
        description: `
          ● Sección para agregar productos.
        `,
        side: "right",
        align: 'start',
      },
    },
    {
      element: '.crear-productos-tutorial',
      popover: {
        title: 'Crear',
        description: `
          ● Puede crear nuevos productos.
        `,
        side: "right",
        align: 'start',
      },
    },
    {
      element: '.agregar-productos-tutorial',
      popover: {
        title: 'Agregar Productos',
        description: `
          ● Para agregar productos a su venta.
        `,
        side: "right",
        align: 'start',
      },
    },
    {
      element: '.descuento-tutorial',
      popover: {
        title: 'Descuento',
        description: `
          ● Puede agregar un descuento al bruto de la venta.
        `,
        side: "right",
        align: 'start',
      },
    },
    {
      element: '.cambiar-descuento-tutorial',
      popover: {
        title: 'Tipo de Descuento',
        description: `
          ● El descuento puede ser por pesos o por % al hacer click en este botón.
        `,
        side: "right",
        align: 'start',
      },
    },
    {
      element: '.tipo-documento-tutorial',
      popover: {
        title: 'Tipo de Documento',
        description: `
          ● Las cotizaciones no descuentan stock de inventario.
        `,
      },
    },
    {
      element: '.metodo-pago-tutorial',
      popover: {
        title: 'Método de Pago',
        description: `
          ● Deshabilitado en las cotizaciones.
        `,
      },
    },
    {
      element: '.registrar-venta-tutorial',
      popover: {
        title: 'Registrar Venta',
        description: `
          ● Para guardar su venta.
        `,
      },
    },
  ];
};

export const sidebarSteps = (driver: Driver): DriveStep[] => {
  return [
    {
      element: 'kuv-sidebar-item:has(.fas.fa-chart-bar)',
      popover: {
        title: 'Reportes de Ventas',
        description: `
          ● Puede visualizar los reportes de su sistema.
        `,
        side: "right",
        align: 'start',
      },
    },
    {
      element: 'kuv-sidebar-item:has(.fas.fa-car)',
      popover: {
        title: 'Taller',
        description: `
          ● Órdenes de Trabajo. <br>
          ● Flujo OT. <br>
          ● Vehículos y Servicios.
        `,
        side: "right",
        align: 'start',
      },
    },
    {
      element: 'kuv-sidebar-item:has(.fas.fa-boxes)',
      popover: {
        title: 'Inventario',
        description: `
          ● Productos y Categorías. <br>
          ● Stock Crítico. <br>
          ● Ajustes de Stock.
        `,
        side: "right",
        align: 'start',
      },
    },
  ];
};

export const crearServicioSteps = (driver: Driver, activeModal: NgbModal): DriveStep[] => {
  return [
    {
      element: '.agregar-servicio-tutorial',
      popover: {
        title: 'Agregar Servicio',
        description: `
          ● Para agregar un nuevo servicio.
        `,
        side: "right",
        align: 'start',
        onNextClick: (element, step, options) => {
          (document.querySelector('.agregar-servicio-tutorial') as HTMLButtonElement)?.click();
          setTimeout(() => {
            driver.moveNext();
          }, 0);
        },
      },
    },
    {
      element: '.nombre-servicio-tutorial',
      popover: {
        title: 'Nombre del Servicio',
        description: `
          ● Obligatorio.
        `,
        side: "right",
        align: 'start',
        onPrevClick: () => {
          activeModal.dismissAll();
          driver.movePrevious();
        }
      },
    },
  ];
};

export const crearProductoSteps = (driver: Driver, activeModal: NgbModal): DriveStep[] => {
  return [
    {
      element: '.agregar-producto-tutorial',
      popover: {
        title: 'Agregar Producto',
        description: `
          ● Para agregar un nuevo producto.
        `,
        onNextClick: (element, step, options) => {
          (document.querySelector('.agregar-producto-tutorial') as HTMLButtonElement)?.click();
          setTimeout(() => {
            driver.moveNext();
          }, 0);
        },
        align: 'start',
      },
    },
    {
      element: '.nombre-producto-tutorial',
      popover: {
        title: 'Nombre del Producto',
        description: `
          ● El nombre del producto es obligatorio.
        `,
        side: "right",
        align: 'start',
        onPrevClick: () => {
          activeModal.dismissAll();
          driver.movePrevious();
        },
        onNextClick: () => {
          driver.moveNext();
        }
      },
    },
    {
      element: '.precio-venta-tutorial',
      popover: {
        title: 'Precio de Venta',
        description: `
          ● Al ingresar un precio, se calcula el otro tras hacer click afuera.
        `,
        side: "right",
        align: 'start',
      },
    },
    {
      element: '.precio-compra-tutorial',
      popover: {
        title: 'Precio de Compra',
        description: `
          ● Al ingresar un precio, se calcula el otro tras hacer click afuera.
        `,
        side: "right",
        align: 'start',
      },
    },
    {
      element: '.stock-inicial-tutorial',
      popover: {
        title: 'Stock Inicial',
        description: `
          ● (Opcional) Se asigna el stock cuando se crea una sucursal .
        `,
        side: "right",
        align: 'start',
      },
    },
    {
      element: '.stock-minimo-tutorial',
      popover: {
        title: 'Stock Mínimo',
        description: `
          ● (Opcional) Para resaltar con rojo si el stock es inferior.
        `,
        side: "right",
        align: 'start',
      },
    },
    {
      element: '.imagen-tutorial',
      popover: {
        title: 'Imagen',
        description: `
          ● (Opcional) Imagen del producto .
        `,
        side: "right",
        align: 'start',
      },
    },
  ];
};
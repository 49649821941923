import { Component, OnInit, Input } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DriveStep, driver } from "driver.js";
import { Router } from '@angular/router';
import { crearOTSteps, crearProductoSteps, crearServicioSteps, crearVentaSteps, sidebarSteps } from 'src/app/utils/tutorial/steps';

@Component({
  selector: 'app-contacto-soporte',
  templateUrl: './contacto-soporte.component.html',
  styleUrls: ['./contacto-soporte.component.scss'],
})
export class ModalContactoSoporteComponent implements OnInit {

  @Input() soloDemo: boolean = true;

  constructor(
    public activeModal: NgbActiveModal,
    private router: Router,
    public modalService: NgbModal,
  ) { }

  ngOnInit() { }

  guardar() {
    this.activeModal.close(true);
  }

  cancelar() {
    this.activeModal.dismiss();
  }

  email(): void {
    const mailText = "mailto:soporte@kuvemar.com+?subject=files&body=";
    window.location.href = mailText;
    this.activeModal.dismiss();
  }

  whatsApp(): void {
    const whatsApp = "https://api.whatsapp.com/send?phone=56944758039";
    window.location.href = whatsApp;
    this.activeModal.dismiss();
  }

  whatsAppKuvemar(): void {
    const whatsApp = "https://api.whatsapp.com/send?phone=56975155189";
    window.location.href = whatsApp;
    this.activeModal.dismiss();
  }

  tel(): void {
    const tel = "tel:+56975155189";
    window.location.href = tel;
    this.activeModal.dismiss();
  }

  iniciarTutorial(): void {
    this.activeModal.close();

    const driverObj = driver({
      // showProgress: true,
      allowClose: false,
      animate: true,
      disableActiveInteraction: true,
      popoverOffset: 15,
      smoothScroll: true,
      showButtons: ['next', 'previous', 'close'],
      doneBtnText: 'Hecho',
      nextBtnText: 'Siguiente',
      prevBtnText: 'Atrás',
      progressText: '{{current}} de {{total}}',
      onPopoverRender: (popover, { config, state }) => {
        if (driverObj.isLastStep()) return;

        const exitButton = document.createElement("button");
        exitButton.innerText = "Salir";
        popover.footerButtons.prepend(exitButton);
        exitButton.addEventListener("click", () => {
          driverObj.destroy();
        });
      },
    });

    // ej: /revisiones/create
    const url = this.router.url;
    // orden del if importa
    if (url.includes('revisiones/create-venta')) {
      driverObj.setSteps(crearVentaSteps(driverObj));
    } else if (url.includes('revisiones/create')) {
      driverObj.setSteps(crearOTSteps(driverObj));
    } else if (url.includes('servicio/index')) {
      driverObj.setSteps(crearServicioSteps(driverObj, this.modalService));
    } else if (url.includes('producto/index')) {
      driverObj.setSteps(crearProductoSteps(driverObj, this.modalService));
    } else {
      driverObj.setSteps(sidebarSteps(driverObj));
    }

    driverObj.drive();
  }
}

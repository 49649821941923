import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxKuvUtilsModule } from 'ngx-kuv-utils';
import { NgxKuvToolsModule } from 'ngx-kuv-tools';
import { CompraCreateSharedComponent } from './compra/compra-create-shared/compra-create-shared.component';
import { NgxKuvCreateVentaModule } from 'ngx-kuv-venta';
import { ModalContactoSoporteComponent } from './contacto-soporte/contacto-soporte.component';

@NgModule({
  declarations: [
    CompraCreateSharedComponent,
    ModalContactoSoporteComponent,
  ],
  imports: [
    CommonModule,
    NgbModalModule,
    NgxKuvUtilsModule,
    NgxKuvToolsModule,
    NgxKuvCreateVentaModule
  ],
  exports:[
    CompraCreateSharedComponent,
    ModalContactoSoporteComponent,
  ]
})
export class SharedModule { }

import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoadingService, AlertService } from 'ngx-kuv-tools';
import { DateService } from 'src/app/utils/date/date.service';
import { FormatterService } from 'src/app/utils/formatter/formatter.service';
import { SuscripcionesService } from '../suscripciones.service';
import { SesionService } from 'src/app/utils/sesion/sesion.service';
import { SocketService } from 'src/app/utils/socket/socket.service';
import { SentryService } from 'src/app/utils/sentry/sentry.service';

@Component({
  selector: 'app-suscripciones-pago-en-proceso',
  templateUrl: './suscripciones-pago-en-proceso.component.html',
  styleUrls: ['./suscripciones-pago-en-proceso.component.scss']
})
export class SuscripcionesPagoEnProcesoComponent implements OnInit {
  @Input() montoParcial: number = 0;
  @Input() tipo: string = 'ANUAL';
  @Input() plan: string = 'FILTRO_X';
  url: string = '';

  constructor(
    public activeModal: NgbActiveModal,
    private loading: LoadingService,
    private alerts: AlertService,
    public service: SuscripcionesService,
    public formatter: FormatterService,
    public date: DateService,
    private sesion: SesionService,
    private socketService: SocketService,
    private sentryService: SentryService,
  ) { }

  ngOnInit(): void { }

  abrirEnlacePago(): void {
    this.socketService.setActiveModalInstanceForClose(this.activeModal);
    this.socketService.startConnection(null);
    if (this.url) {
      window.open(this.url, "_blank");
      return;
    }
    this.loading.show();
    this.service.getEnlacePago(this.sesion.user.empresa_id, { intervalo: this.tipo, plan: this.plan, productos: ['LUBRIKUV'], monto_parcial: this.montoParcial }).subscribe({
      next: (res: any) => {
        this.loading.hide();
        this.url = res.url;
        window.open(this.url, "_blank");
      },
      error: (err: any) => {
        console.error(err);
        this.loading.hide();
        this.alerts.addAlert("Error al obtener el enlace de Pago.", "danger");
        this.sentryService.captureException(err.error);
      }
    });
  }

  onDismiss(): void {
    this.socketService.disconnect();
    this.activeModal.dismiss('Cross click');
  }
}

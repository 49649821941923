import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import * as Sentry from "@sentry/angular-ivy";

@Injectable({
  providedIn: 'root'
})
export class SentryService {
  captureException(err: any): void {
    try {
      if(!environment.production || !err) return;
      Sentry.captureException(err);
    } catch (error) {}
  }
}
import { Injectable } from '@angular/core';
import { io } from 'socket.io-client';
import { ConfigService } from '../config/config.service';
import { Socket } from 'socket.io-client';
import { Router } from '@angular/router';
import { SesionService } from '../sesion/sesion.service';
import { AlertService } from 'ngx-kuv-tools';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  private socket: Socket | undefined;
  private activeModalInstance: NgbActiveModal | undefined;

  constructor(
    private config: ConfigService,
    private router: Router,
    private sesion: SesionService,
    private alerts: AlertService,
  ) { }

  startConnection(callback: any): void {
    // Conectar al servidor Node.js
    this.socket = io(this.config.urlBase);

    // Manejar eventos del servidor
    this.socket.on(`message-${this.sesion.user.empresa_id}`, (mensaje: string) => {
      console.log('Mensaje recibido del servidor:', mensaje);
      if (mensaje == 'payment-finished-successfully') {
        this.router.navigate(['/']);
        if (this.activeModalInstance) this.activeModalInstance?.close();
        this.alerts.addAlert("El pago de su suscripción LubriKuv se ha realizado correctamente.", "success");
        this.activeModalInstance = undefined;
        setTimeout(() => {
          window.location.reload();
        }, 800);
      } else {
        if(callback) callback();
      }
    });
  }

  disconnect(): void {
    this.socket?.disconnect();
    this.socket?.close();
  }

  enviarMensaje(mensaje: string): void {
    // Enviar mensaje al servidor
    this.socket!.emit('mensaje', mensaje);
  }

  setActiveModalInstanceForClose(activeModal: NgbActiveModal): void {
    this.activeModalInstance = activeModal;
  }
}
